import React, {useState} from "react";
import {
    Alert,
    Button,
    Chip,
    Dialog,
    DialogBody,
    DialogFooter,
    DialogHeader,
    IconButton,
    Input,
    Option,
    Select,
    Step,
    Stepper,
    Switch,
    Typography,
} from "@material-tailwind/react";
import {NavLink, useSearchParams} from "react-router-dom";
import {useGetApiKeyQuery} from "@sv-studios/react-utils";
import {FaExternalLinkAlt, FaTimes} from "react-icons/fa";
import {LuExternalLink} from "react-icons/lu";

export function ApiKeyWizard({}) {
    const [activeStep, setActiveStep] = useState(0);
    const [isLastStep, setIsLastStep] = useState(false);
    const [isFirstStep, setIsFirstStep] = useState(false);

    const [os, setOs] = useState<string | null>(null);
    const [program, setProgram] = useState<string | null>(null);
    const [preset, setPreset] = useState<string | null>(null);
    const [variables, setVariables] = useState({});

    const handleNext = (count = 1) =>
        !isLastStep && setActiveStep((cur) => cur + count);
    const handlePrev = (count = 1) =>
        !isFirstStep && setActiveStep((cur) => cur - count);

    const [searchParams, setSearchParams] = useSearchParams();

    const close = () => {
        searchParams.delete("show_wizard");
        setSearchParams(searchParams);
    };

    const {data: apiKey, isLoading: apiKeyIsLoading} = useGetApiKeyQuery();


    return (
        <>
            <Dialog open={searchParams.has("show_wizard")} handler={close}>
                <DialogHeader>
                    API-Key Wizard
                    <IconButton
                        onClick={close}
                        variant="text"
                        color="gray"
                        className="ml-auto dark:text-gray-200"
                    >
                        <FaTimes/>
                    </IconButton>
                </DialogHeader>
                <DialogBody>
                    {!apiKeyIsLoading && !apiKey?.id && (
                        <Alert
                            color="orange"
                            className="mb-5 font-normal dark:bg-orange-700/75"
                        >
                            Du benötigst einen API-Key um diesen Wizard vollständig nutzen zu
                            können. Ansonsten musst du den API Key nachträglich in die
                            Konfiguration einfügen.
                        </Alert>
                    )}
                    <div className="w-full px-12">
                        <Stepper
                            activeStep={activeStep}
                            isLastStep={(value) => setIsLastStep(value)}
                            isFirstStep={(value) => setIsFirstStep(value)}
                            lineClassName="bg-gray-400/50"
                            activeLineClassName="bg-green-500"
                        >
                            <Step
                                activeClassName="dark:bg-white dark:text-gray-900"
                                className="bg-gray-400"
                                completedClassName="bg-green-500"
                                onClick={() => setActiveStep(0)}
                            >
                                1
                                <div className="absolute -bottom-[2rem] w-max text-center">
                                    <Typography
                                        variant="h6"
                                        className="text-gray-900 dark:text-gray-200"
                                    >
                                        Programmauswahl
                                    </Typography>
                                </div>
                            </Step>
                            <Step
                                activeClassName="dark:bg-white dark:text-gray-900"
                                className="bg-gray-400"
                                completedClassName="bg-green-500"
                                onClick={() => setActiveStep(1)}
                            >
                                2
                                <div className="absolute -bottom-[2rem] w-max text-center">
                                    <Typography
                                        variant="h6"
                                        className="text-gray-900 dark:text-gray-200"
                                    >
                                        Variablen
                                    </Typography>
                                </div>
                            </Step>
                            <Step
                                activeClassName="dark:bg-white dark:text-gray-900"
                                className="bg-gray-400"
                                completedClassName="bg-green-500"
                                onClick={() => setActiveStep(2)}
                            >
                                3
                                <div className="absolute -bottom-[2rem] w-max text-center">
                                    <Typography
                                        variant="h6"
                                        className="text-gray-900 dark:text-gray-200"
                                    >
                                        Einrichten
                                    </Typography>
                                </div>
                            </Step>
                        </Stepper>
                    </div>
                    {activeStep === 0 && (
                        <div id="program">
                            <div className="mt-16 flex flex-col justify-between gap-4">
                                <Typography className="mb-2 dark:text-gray-200" variant="h6">
                                    Um dir die Besten Einstellungen zu empfehlen, benötigen wir
                                    ein paar Informationen.
                                </Typography>
                                <Select
                                    label="Betriebsystem"
                                    onChange={(e) => setOs(String(e))}
                                    value={os || undefined}
                                >
                                    {[
                                        {
                                            name: "Windows",
                                            value: "windows",
                                            icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5f/Windows_logo_-_2012.svg/132px-Windows_logo_-_2012.svg.png?20220903072431",
                                        },
                                        {
                                            name: "Linux",
                                            value: "linux",
                                            icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/35/Tux.svg/1200px-Tux.svg.png",
                                        },
                                        {
                                            name: "MacOS",
                                            value: "macos",
                                            icon: "https://upload.wikimedia.org/wikipedia/commons/c/c9/Finder_Icon_macOS_Big_Sur.png",
                                        },
                                    ].map(({name, icon, value}) => (
                                        <Option value={value} key={value}>
                                            <div className="flex flex-row">
                                                <img
                                                    src={icon}
                                                    alt={name + " Icon"}
                                                    className="mr-2 h-5"
                                                />
                                                {name}
                                            </div>
                                        </Option>
                                    ))}
                                </Select>

                                <Select
                                    label="Welches Programm willst du nutzen?"
                                    disabled={!os}
                                    onChange={(e) => setProgram(String(e))}
                                    value={program || undefined}
                                >
                                    {[
                                        {
                                            name: "ShareX",
                                            value: "sharex",
                                            icon: "https://avatars.githubusercontent.com/u/5548736?s=280&v=4",
                                            os: ["windows"],
                                        },
                                        {
                                            name: "AutoHotkey",
                                            value: "autohotkey",
                                            icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Modern_AutoHotkey_Logo_%28no_text%29.svg/1200px-Modern_AutoHotkey_Logo_%28no_text%29.svg.png",
                                            os: ["windows"],
                                        },
                                        {
                                            name: "Flameshot",
                                            value: "flameshot",
                                            icon: "https://avatars.githubusercontent.com/u/36951528?s=200&v=4",
                                            os: ["linux"],
                                        },
                                        {
                                            name: "Lightshot",
                                            value: "lightshot",
                                            icon: "https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/ca/33/b8/ca33b800-7432-ad08-3341-70c2cd308671/lightshot.png/1200x630bb.png",
                                            os: ["windows", "macos"],
                                        },
                                    ]
                                        .filter(({os: osList}) => osList.includes(String(os)))
                                        .map(
                                            ({
                                                 name = "",
                                                 icon,
                                                 value,
                                             }) => (
                                                <Option value={value} key={value}>
                                                    <div className="flex flex-row">
                                                        {icon && (
                                                            <img
                                                                src={icon}
                                                                alt={name + " Icon"}
                                                                className="mr-2 h-5 max-w-[100px]"
                                                            />
                                                        )}
                                                        {name}
                                                    </div>
                                                </Option>
                                            )
                                        )}
                                </Select>

                                <Typography variant="small" className="dark:text-gray-400">
                                    Wenn du den Unterschied nicht kennst, schaue dir unsere
                                    Übersicht auf unserer{" "}
                                    <a
                                        href="https://docs.sv-studios.net/?"
                                        className="hover:underline"
                                    >
                                        Dokumentation
                                    </a>{" "}
                                    an.
                                </Typography>
                            </div>
                        </div>
                    )}
                    {activeStep === 1 && (
                        <div id="variables">
                            <div className="mt-16 flex flex-col justify-between gap-4">
                                <Typography className="mb-2 dark:text-gray-200" variant="h6">
                                    Du hast bei einigen Programmen die Möglichkeit, Variablen zu
                                    nutzen. Heißt du kannst die Datei umbenennen, etc.
                                </Typography>
                                <Select
                                    label="Preset"
                                    onChange={(e) => setPreset(String(e))}
                                    value={preset || undefined}
                                >
                                    <Option value="minimal">Minimal (keine Variablen)</Option>
                                    <Option value="standard">
                                        Standard (Name, Beschreibung)
                                    </Option>
                                    <Option value="custom">Benutzerdefiniert</Option>
                                </Select>
                                <Typography variant="small" className="dark:text-gray-400">
                                    Verschiedene Programme haben verschiedene Variablen. Bei
                                    manchen hast du mehr Einstellungsmöglichkeiten als bei
                                    anderen.
                                </Typography>

                                {[
                                    {
                                        parameter: "name",
                                        label: "Name",
                                        preset: ["standard", "custom"],
                                        program: ["sharex", "autohotkey", "lightshot"],
                                        type: "text",
                                        default: "Bild %d.%mo.%y (%h:%mi:%s)",
                                    },
                                    {
                                        parameter: "code",
                                        label: "Code",
                                        preset: ["custom"],
                                        program: ["sharex", "autohotkey", "lightshot"],
                                        type: "password",
                                    },
                                    {
                                        parameter: "password",
                                        label: "Passwort",
                                        preset: ["custom"],
                                        program: ["sharex", "autohotkey", "lightshot"],
                                        type: "password",
                                    },
                                    {
                                        parameter: "expireAt",
                                        label: "Ablaufdatum",
                                        preset: ["custom"],
                                        program: ["sharex", "autohotkey", "lightshot"],
                                        type: "date",
                                    },
                                    {
                                        parameter: "maxUses",
                                        label: "Maximale Aufrufe",
                                        preset: ["custom"],
                                        program: ["sharex", "autohotkey", "lightshot"],
                                        type: "number",
                                    },
                                ]
                                    .filter(({preset: presetList, program: programList}) => {
                                        if (presetList && !presetList.includes(preset))
                                            return false;
                                        return !(programList && !programList.includes(program));
                                    })

                                    .map(({parameter, label, type, default: defaultValue}) => (
                                        <div className="flex flex-col items-center gap-2 md:flex-row">
                                            <div className="">
                                                <Switch
                                                    label={label + " abfragen"}
                                                    checked={variables[parameter]?.enabled}
                                                    onChange={(e) =>
                                                        setVariables({
                                                            ...variables,
                                                            [parameter]: {
                                                                ...variables[parameter],
                                                                enabled: e.target.checked,
                                                            },
                                                        })
                                                    }
                                                />
                                            </div>

                                            {type === "text" && (
                                                <div className="grow">
                                                    <Input
                                                        label={"Standardwert"}
                                                        value={variables[parameter]?.value}
                                                        defaultValue={defaultValue}
                                                        disabled={!variables[parameter]?.enabled}
                                                        onChange={(e) =>
                                                            setVariables({
                                                                ...variables,
                                                                [parameter]: {
                                                                    ...variables[parameter],
                                                                    value: e.target.value,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {type === "number" && (
                                                <div className="grow">
                                                    <Input
                                                        type="number"
                                                        label={"Standardwert"}
                                                        defaultValue={defaultValue}
                                                        value={variables[parameter]?.value}
                                                        disabled={!variables[parameter]?.enabled}
                                                        onChange={(e) =>
                                                            setVariables({
                                                                ...variables,
                                                                [parameter]: {
                                                                    ...variables[parameter],
                                                                    value: e.target.value,
                                                                },
                                                            })
                                                        }
                                                    />
                                                </div>
                                            )}
                                            {type === "date" && (
                                                <>
                                                    <div className="grow">
                                                        <Input
                                                            label={"Standardwert"}
                                                            type="number"
                                                            defaultValue={defaultValue}
                                                            value={variables[parameter]?.value}
                                                            disabled={!variables[parameter]?.enabled}
                                                            onChange={(e) =>
                                                                setVariables({
                                                                    ...variables,
                                                                    [parameter]: {
                                                                        ...variables[parameter],
                                                                        value: e.target.value,
                                                                    },
                                                                })
                                                            }
                                                        />
                                                    </div>
                                                    <div className="grow">
                                                        <Select
                                                            label={"Zeiteinheit"}
                                                            defaultValue={defaultValue}
                                                            value={variables[parameter]?.unit}
                                                            disabled={!variables[parameter]?.enabled}
                                                            onChange={(e) =>
                                                                setVariables({
                                                                    ...variables,
                                                                    [parameter]: {
                                                                        ...variables[parameter],
                                                                        unit: e,
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <Option value={1000}>Sekunden</Option>
                                                            <Option value={60000}>Minuten</Option>
                                                            <Option value={3600000}>Stunden</Option>
                                                            <Option value={86400000}>Tage</Option>
                                                            <Option value={604800000}>Wochen</Option>
                                                            <Option value={2592000000}>Monate</Option>
                                                            <Option value={31536000000}>Jahre</Option>
                                                        </Select>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    )}
                    {activeStep === 2 && (
                        <div id="check">
                            <div className="mt-16 flex flex-col justify-between gap-4">
                                <Typography className="mb-2 dark:text-gray-200" variant="h6">
                                    Hier ist nochmal eine Übersicht über deine Auswahl.
                                </Typography>
                                <div className="grid grid-cols-2 gap-2">
                                    <Typography
                                        variant="h6"
                                        className="font-normal dark:text-gray-400"
                                    >
                                        Betriebsystem
                                    </Typography>
                                    <Typography variant="paragraph" className="dark:text-gray-400">
                                        {os}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        className="font-normal dark:text-gray-400"
                                    >
                                        Programm
                                    </Typography>
                                    <Typography variant="paragraph" className="dark:text-gray-400">
                                        {program}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        className="font-normal dark:text-gray-400"
                                    >
                                        Preset
                                    </Typography>
                                    <Typography variant="paragraph" className="dark:text-gray-400">
                                        {preset}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        className="font-normal dark:text-gray-400"
                                    >
                                        Variablen
                                    </Typography>
                                    <div className="flex flex-wrap gap-2">
                                        {Object.entries(variables)
                                            .filter(([key, value]) => value.enabled)
                                            .map(([key, value]) => (
                                                <>
                                                    <Chip
                                                        value={key + ":" + Object.entries(value).join(",")}
                                                        color="blue-gray"
                                                    />
                                                </>
                                            ))}
                                    </div>
                                </div>
                                <div className="flex">
                                    <Typography
                                        variant="h6"
                                        className="inline dark:text-gray-200"
                                    >
                                        Wenn du mit deiner Auswahl zufrieden bist, kannst du dir
                                        jetzt deine Konfiguration herunterladen. Weitere
                                        Informationen zum Einrichten findest du in unserer{" "}
                                        <a href="https://docs.sv-studios.net/?">Dokumentation</a>.
                                    </Typography>
                                    <NavLink to="https://docs.sv-studios.net/?">
                                        <Button variant="outlined">
                                            <LuExternalLink size={16} className="inline mr-2"/>
                                            Dokumentation
                                        </Button>
                                    </NavLink>
                                </div>

                                <div className="flex flex-wrap gap-2">
                                    <Button variant="gradient" color="indigo" className="grow">
                                        Konfiguration herunterladen
                                    </Button>
                                    <Button variant="gradient" color="indigo" className="grow">
                                        Konfiguration in die Zwischenablage kopieren
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                </DialogBody>
                {activeStep < 2 && <DialogFooter>
                    {activeStep === 0 && (
                        <div className="flex flex-col gap-4 sm:flex-row">
                            <Button onClick={() => handleNext(1)} disabled={!program}>
                                Weiter zu den Variablen
                            </Button>
                            <Button onClick={() => handleNext(2)} disabled={!program}>
                                Direkt zum Download
                            </Button>
                        </div>
                    )}
                    {activeStep === 1 && (
                        <div className="flex w-full flex-col justify-between gap-4 sm:flex-row">
                            <Button onClick={() => handlePrev(1)}>
                                Zurück zur Programmauswahl
                            </Button>
                            <Button onClick={() => handleNext(1)}>Weiter zum Download</Button>
                        </div>
                    )}
                </DialogFooter>}
            </Dialog>
        </>
    );
}
