import {IconButton} from "@material-tailwind/react";
import {useMemo} from "react";
import {Pageable} from "@/model/api/PageableModel";
import {FaArrowLeft, FaArrowRight, FaEllipsis} from "react-icons/fa6";


export function Pagination({pageData, setPage}: {
    pageData: Pageable;
    setPage: (arg0: number) => void;
}) {
    return (
        <LocalPagination
            pageSize={pageData.size}
            totalCount={pageData.totalElements}
            currentPage={pageData.number}
            onPageChange={setPage}
            siblingCount={2}
        />
    );
}

const range = (start: number, end: number) => {
    let length = end - start + 1;
    /*
            Create an array of certain length and set the elements within it from
          start value to end value.
        */
    return Array.from({length}, (_, idx) => idx + start);
};

const DOTS = "...";

export const usePagination = ({
                                  totalCount,
                                  pageSize,
                                  siblingCount = 1,
                                  currentPage,
                              }: {
    totalCount: number;
    pageSize: number;
    siblingCount?: number;
    currentPage: number;
}) => {
    return useMemo(() => {
        const totalPageCount = Math.ceil(totalCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        /*
                  Case 1:
                  If the number of pages is less than the page numbers we want to show in our
                  paginationComponent, we return the range [1..totalPageCount]
                */
        if (totalPageNumbers >= totalPageCount) {
            return range(0, totalPageCount - 1);
        }

        /*
                    Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
                */
        const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
        const rightSiblingIndex = Math.min(
            currentPage + siblingCount,
            totalPageCount
        );

        /*
                  We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
                */
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 0;
        const lastPageIndex = totalPageCount - 1;

        /*
                    Case 2: No left dots to show, but rights dots to be shown
                */
        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(0, leftItemCount);

            return [...leftRange, DOTS, totalPageCount - 1];
        }

        /*
                    Case 3: No right dots to show, but left dots to be shown
                */
        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(
                totalPageCount - rightItemCount,
                totalPageCount - 1
            );
            return [firstPageIndex, DOTS, ...rightRange];
        }

        /*
                    Case 4: Both left and right dots to be shown
                */
        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [totalCount, pageSize, siblingCount, currentPage]);
};

const LocalPagination = ({
                             onPageChange,
                             totalCount,
                             siblingCount = 1,
                             currentPage,
                             pageSize,
                         }: {
    onPageChange: (arg0: number) => void;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
}) => {
    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
    });

    // If there are less than 2 times in pagination range we shall not render the component
    if (!paginationRange || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];
    return (
        <ul className={"flex items-center gap-2"}>
            {/* Left navigation arrow */}
            <IconButton disabled={currentPage === 0} onClick={onPrevious} size="sm">
                <FaArrowLeft/>
            </IconButton>
            {paginationRange?.map((pageNumber, index) => {
                // If the pageItem is a DOT, render the DOTS unicode character
                if (pageNumber === DOTS) {
                    return (
                        <IconButton size="sm" variant="text" key={pageNumber + " " + index}>
                            <FaEllipsis/>
                        </IconButton>
                    );
                }


                // Render our Page Pills
                return (
                    <IconButton
                        key={pageNumber}
                        size="sm"
                        onClick={() => onPageChange(Number(pageNumber))}
                        variant={pageNumber === currentPage ? "filled" : "text"}
                    >
                        {pageNumber}
                    </IconButton>
                );
            })}
            {/*  Right Navigation arrow */}
            <IconButton
                disabled={currentPage === lastPage}
                onClick={onNext}
                size="sm"
            >
                <FaArrowRight/>
            </IconButton>
        </ul>
    );
};
