import {useGetHealthQuery, useGetInfoQuery,} from "@/store/admin/admin-api";
import {Card, CardBody, Chip, Input, Typography,} from "@material-tailwind/react";
import {Component, GetHealthResponse} from "@/model/api/admin/SystemHealthModel";
import {FaHeart, FaSpinner} from "react-icons/fa6";
import React from "react";

export function BackendInfo() {
    const {
        data: infoData,
        isFetching: infoIsFetching,
    } = useGetInfoQuery();

    const {
        data: healthData,
        isFetching: healthIsFetching,
        isSuccess: healthIsSuccess,
    } = useGetHealthQuery();

    const fetchIcon = infoIsFetching ? (
        <FaSpinner className="animate-spin inline"/>
    ) : null;

    const healthIcon = (component: GetHealthResponse | Component) =>
        component.status === "UP" ? (
            <Chip value={component.status} color="green"/>
        ) : (
            <Chip value={component.status} color="red"/>
        );

    const calcDiskSpace = (component: Component) => {
        /**
         * @param {number} x
         * @returns {string}
         */
        const local = (x: number): string =>
            x.toLocaleString(undefined, {
                unit: "gigabyte",
                style: "unit",
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
            });

        if (component.status === "UP") {
            const total = Number(component.details?.total) / Math.pow(10, 9);
            const free = Number(component.details?.free) / Math.pow(10, 9);
            const threshold = Number(component.details?.threshold) / Math.pow(10, 9);
            const used = total - free;
            const percent = Math.round((used / total) * 100);
            return `${percent}% (${local(used)} / ${local(total)})`;
        } else {
            return "N/A";
        }
    };

    return (
        <>
            <div className="flex flex-col gap-4">
                <Card>
                    <CardBody>
                        <Typography
                            variant="lead"
                            color="gray"
                            className="mb-5 dark:text-white"
                        >
                            Health <FaHeart className="animate-pulse text-red-500 inline"/>
                        </Typography>
                        {healthIsFetching && (
                            <div className="h-96 w-full animate-pulse rounded-lg bg-panel-600"/>
                        )}
                        {healthIsSuccess && (
                            <table className="my-2 w-full table-auto border-y dark:border-panel-600">
                                <thead className="text-left">
                                <tr className="border-blue-gray-100 dark:border-panel-600 dark:bg-panel-700/50">
                                    <th className="p-2">Component</th>
                                    <th className="p-2 dark:border-panel-600">Details</th>
                                    <th className="p-2">Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="p-2">Core</td>
                                    <td className="p-2"></td>
                                    <td className="p-2">{healthIcon(healthData)}</td>
                                </tr>
                                {Object.entries(healthData.components || {}).map(
                                    ([key, value], index) => (
                                        <tr
                                            className={`${
                                                index % 2 === 0 && "dark:bg-panel-700/50"
                                            }`}
                                        >
                                            <td className="p-2">{key}</td>
                                            <td className="p-2">
                                                {key === "diskSpace" && calcDiskSpace(value)}
                                                {key === "db" && value.details?.database}
                                                {key === "redis" && value.details?.version}
                                            </td>
                                            <td className="p-2">{healthIcon(value)}</td>
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </table>
                        )}
                        Groups: {healthData?.groups.join(", ")}
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Typography
                            variant="lead"
                            color="gray"
                            className="mb-5 dark:text-white"
                        >
                            Build
                        </Typography>
                        <div className="flex flex-wrap gap-3">
                            <Input
                                value={infoData?.build?.name}
                                label="Name"
                                readOnly
                                icon={fetchIcon}
                            />
                            <Input
                                value={infoData?.build?.version}
                                label="Version"
                                readOnly
                                icon={fetchIcon}
                            />
                            <Input
                                value={new Date(infoData?.build?.time || 0).toLocaleString()}
                                label="Build Time"
                                readOnly
                                icon={fetchIcon}
                            />
                            <Input
                                value={infoData?.build?.artifact}
                                label="Artifact"
                                readOnly
                                icon={fetchIcon}
                            />
                            <Input
                                value={infoData?.build?.group}
                                label="Group"
                                readOnly
                                icon={fetchIcon}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>
        </>
    );
}
